import {Button, FormControl, Select, styled, TextField} from "@mui/material";

export const CssTextField = styled(TextField)({
    width: '40vh',
    '& label': {
        color: 'white',
    },
    '& label.Mui-focused': {
        color: '#c96bff',
    },
    '& input': {
        color: 'white',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#c96bff',
        },
    },
});

export const CssFormControl = styled(FormControl)({
    '& label': {
        color: 'white',
    },
    '& label.Mui-focused': {
        color: '#c96bff',
    },
    '& input': {
        color: 'white',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#c96bff',
        },
    }
});

export const CssSelect = styled(Select)({
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#c96bff',
    },
    '& .MuiSelect-select': {
        color: 'white',
    },
    '& .MuiSvgIcon-root ': {
        fill:"white",
    }
});

export const CssButton = styled(Button)({
    width: '40vh',
    fontSize:'3vh',
    color: 'white', // Text color
    border: '1px solid',
    borderRadius:'20px',
    borderColor:'white',
    backgroundColor: 'transparent', // Default background color
    // border: '1px solid white', // Default border color
    '&:hover': {
        // backgroundColor: 'white',
        color: '#c96bff', // Text color on hover
        borderColor: '#c96bff', // Border color on hover
    },
    '&.Mui-focused': {
        backgroundColor: 'transparent', // Background color when focused
        color: '#c96bff', // Text color when focused
        borderColor: '#c96bff', // Border color when focused
    },
    '&:active': {
        boxShadow: 'none', // Removes the box shadow on click
        backgroundColor: '#c96bff', // Background color when active
        borderColor: '#c96bff', // Border color when active
    },
    '&.Mui-disabled': {
        opacity: 0.5, // Adds transparency to the disabled state
        color: 'white', // Keeps text color for disabled state
        borderColor: 'white', // Keeps border color for disabled state
    },
});