import quizStyle from "./Quiz.module.css";
import {useEffect, useState} from "react";
import axios from 'axios';

import QUESTIONS from '../questions.js';
import questionStyle from '../components/Question.module.css';

import { InputLabel, MenuItem} from "@mui/material";
import {CssTextField, CssFormControl, CssSelect, CssButton} from "../mui-styles/styles";

import logoAngelini from "../assets/angelini-logo.png";
import logoPurple from "../assets/logo-purple-day.png"
import {useNavigate} from "react-router-dom";

const API_URL = 'https://purple-day-2024-api-33zerqsroq-ew.a.run.app/add';


function RemoteQuiz() {
    const [name, setName] = useState('');
    const [country, setCountry] = useState('');

    const [started, setStarted] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [userAnswer, setUserAnswer] = useState([]);
    const [score, setScore] = useState(null);

    const activeQuestionIndex = userAnswer.length;
    const quizIsComplete = activeQuestionIndex === QUESTIONS.length;

    useEffect(() => {
        if(quizIsComplete) {
            const score = calculateScore();

            const userInfo = {
                displayName: name,
                country: country,
                answers: userAnswer,
                mode: 'online',
                score: score
            }

            sendUserInfo(userInfo);
            setScore(score);
        }
    }, [quizIsComplete]);

    const countries = [
        "Angelini Holding",
        "Angelini Real Estate",
        "Angelini Ventures",
        "Fondazione Angelini",
        "Angelini Pharma Austria",
        "Angelini Pharma Bulgaria",
        "Angelini Pharma Czeck Rep",
        "Angelini Pharma FRABENE",
        "Angelini Pharma Germany",
        "Angelini Pharma Greece",
        "Angelini Pharma Hungary",
        "Angelini Pharma Italy",
        "Angelini Pharma Nordics",
        "Angelini Pharma Poland",
        "Angelini Pharma Portugal",
        "Angelini Pharma Romania",
        "Angelini Pharma Russia",
        "Angelini Pharma Slovakia",
        "Angelini Pharma Spain",
        "Angelini Pharma Switzerland",
        "Angelini Pharma Turkey",
        "Angelini Pharma UK",
        "Angelini Pharma US"
    ];
    const correctAnswers = ['C', 'B', 'C', 'A', 'C', 'B'];

    const startQuiz = () => {
        // Logic to show quiz questions
        setStarted(true);
    };

    const restartGame = () => {
        setUserAnswer([]);
    }

    const navigate = useNavigate();

    const seeRanking = () => {
        navigate('/ranking');
    }

    function calculateScore() {
        let score = 0;
        for (let i = 0; i < userAnswer.length; i++) {
            if (userAnswer[i] === correctAnswers[i]) {
                score += 10; // Aggiungi 10 punti per ogni risposta corretta
            }
        }
        return score;
    }

    function handleSelectAnswer(answer) {
        setSelectedAnswer(answer);
    }

    function handleConfirmAnswer() {
        setUserAnswer((prevUserAnswers) => {
            return [...prevUserAnswers, selectedAnswer[0]];
        })
        setSelectedAnswer('');
    }
    const sendUserInfo = async (data) => {
        try {
            const response = await axios.post(API_URL, data);
            console.log('Dati aggiunti con successo', response.data);
        } catch (error) {
            console.error('Errore durante l\'aggiunta dei dati', error);
            // Gestisci qui l'errore
        }
    };

    if(quizIsComplete) {
        // Handle quiz completition
        return (
            <div className={quizStyle.mainContainer}>
                <img src={logoPurple} alt={'logo'} className={quizStyle.logoPurple}/>
                <img src={logoAngelini} alt={'logo'} className={quizStyle.logoAngelini}/>
                <h2>THANKS FOR PLAYING!</h2>
                <h2 style={{margin: 0}}>YOUR LEVEL OF AWARENESS IS:</h2>
                <h1 style={{fontSize: '20vh', margin: 0}}>{score} </h1>
                <h2 style={{fontSize: '5vh', marginTop: '-2.5vh',color:"#6d78b7"}}>OUT OF 60</h2>
                <CssButton style={{marginBottom:'2vh'}} onClick={seeRanking}>See Ranking</CssButton>
                <CssButton onClick={restartGame}>Play Again</CssButton>
            </div>
        )
    }

    const isFormValid = name && country;

    return (
        <div className={quizStyle.mainContainer}>
            <img src={logoPurple} alt={'logo'} className={quizStyle.logoPurple}/>
            <img src={logoAngelini} alt={'logo'} className={quizStyle.logoAngelini}/>
            {!started &&
                <div className={quizStyle.formContainer}>
                    <form>
                        <div className={quizStyle.inputContainer}>
                            <CssTextField
                                fullWidth
                                id="name"
                                label="NAME"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            >
                            </CssTextField>
                            <CssFormControl variant="outlined" sx={{minWidth: 120}} >
                                <InputLabel id="age">COMPANY</InputLabel>
                                <CssSelect
                                    labelId="company"
                                    label="COMPANY"
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                >
                                    {countries.map((el) => (
                                        <MenuItem value={el}>{el}</MenuItem>
                                    ))
                                    }
                                </CssSelect>
                            </CssFormControl>
                        </div>
                    </form>
                    <CssButton onClick={startQuiz} disabled={!isFormValid} style={{}}>Confirm</CssButton>
                </div>
            }
            {started &&
                <div className={questionStyle.questionContainer}>

                    <h1 className={questionStyle.question}>{QUESTIONS[activeQuestionIndex].text.toUpperCase()}</h1>
                    <ul style={{listStyleType: 'none', paddingInlineStart: '0px', margin: 0}}>
                        {QUESTIONS[activeQuestionIndex].answers.map((answer) => (
                            <li
                                className={`${questionStyle.option} ${selectedAnswer[0] === answer[0] ? questionStyle.selected : ''}`}
                                onClick={() => {
                                    handleSelectAnswer(answer)
                                }}
                            >
                                <p>{answer[1].toUpperCase()}</p>
                            </li>
                        ))}
                    </ul>
                    <h3 style={{marginTop: 0}}>{activeQuestionIndex + 1} / 6</h3>
                    <CssButton onClick={handleConfirmAnswer} disabled={!selectedAnswer} style={{}}>Confirm</CssButton>
                </div>
            }

        </div>
    )
}

export default RemoteQuiz;