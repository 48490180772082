import { initializeApp } from 'firebase/app';
import { getFirestore} from 'firebase/firestore';
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBrz6iOAfUvlLCWs6sq8syYTR91f9sWlZI",
    authDomain: "purple-day-2024.firebaseapp.com",
    projectId: "purple-day-2024",
    storageBucket: "purple-day-2024.appspot.com",
    messagingSenderId: "809025662819",
    appId: "1:809025662819:web:96fb440fcaba16ac6f2d56"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);


export {app, db}

