import './App.css';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import RemoteQuiz from "./pages/RemoteQuiz";
import Quiz from "./pages/Quiz";
import LastAnswer from "./pages/LastAnswer";
import Ranking from "./pages/Ranking";
import RankingObs from "./pages/obs/RankingObs";
import LastAnswerObs from "./pages/obs/LastAnswerObs";
import Podcast from "./pages/Podcast";

const router = createBrowserRouter([
    { path: '/remote-quiz', element: <RemoteQuiz/>},
    { path: '/quiz', element: <Quiz/>},
    { path: '/last-answer', element: <LastAnswer/>},
    { path: '/ranking', element: <Ranking/>},
    { path: '/ranking-obs', element: <RankingObs/>},
    { path: '/last-answer-obs', element: <LastAnswerObs/>},
    { path: '/podcast', element: <Podcast/>},
    { path: '/', element: <Podcast/>}
])

function App() {
    return <RouterProvider router={router} />;
}

export default App;
