import {db} from "../firebase/clientApp";
import {doc} from 'firebase/firestore';
import quizStyle from "./Quiz.module.css";
import lastStyle from "./LastAnswer.module.css"
import { useDocument } from 'react-firebase-hooks/firestore';
import logoPurple from "../assets/logo-purple-day.png";
import logoAngelini from "../assets/angelini-logo.png";

function LastAnswer() {

    const message = "THANKS FOR PLAYING \n"

    const [value, loading, error] = useDocument(
        doc(db, 'users', 'lastuser'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    if (loading) return (
        <div className={quizStyle.mainContainer}>
            <h1>Loading data...</h1>
        </div>
    );
    if (error) return <p>Error: {error.message}</p>;

    // Accessing the document's data
    const lastAnswer = value?.data();

    if(lastAnswer) {
        return (
            <div className={quizStyle.mainContainer}>
                <img src={logoPurple} alt={'logo'} className={quizStyle.logoPurple}/>
                <img src={logoAngelini} alt={'logo'} className={quizStyle.logoAngelini}/>
                <h2 style={{whiteSpace: "pre-wrap"}}>{message}
                    <span style={{color:"#6d78b7"}}>
                        {lastAnswer.displayName.toUpperCase()}
                    </span>
                </h2>
                <h2 style={{margin: 0}}>YOUR LEVEL OF AWARENESS IS:</h2>
                <h1 className={lastStyle.score} >{lastAnswer.score} </h1>
                <h2 style={{fontSize: '5vh', marginTop: '0vh',color:"#6d78b7"}}>OUT OF <span>60</span></h2>
            </div>
        )
    }

    return (
        <div className={quizStyle.mainContainer}>
            <img src={logoPurple} alt={'logo'} className={quizStyle.logoPurple}/>
            <img src={logoAngelini} alt={'logo'} className={quizStyle.logoAngelini}/>
            <h2>NO ONE PLAYED</h2>

        </div>
    )

}

export default LastAnswer;