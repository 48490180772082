import axios from "axios";
import {useEffect, useState} from "react";

import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

import flagMap from "../data/countryFlags";

import quizStyle from "./Quiz.module.css";
import logoPurple from "../assets/logo-purple-day.png";
import logoAngelini from "../assets/angelini-logo.png";
import rankingStyle from "./Ranking.module.css";
import {CssButton} from "../mui-styles/styles";



const API_URL = 'https://purple-day-2024-api-33zerqsroq-ew.a.run.app/leaderboard';

function Ranking() {
    const [rankingData, setRankingData] = useState(null);
    const [startIndex, setStartIndex] = useState(0);

    const handleNext = () => {
        const newIndex = Math.min(startIndex + 5, rankingData["leaderBoard"].length - 1);
        setStartIndex(newIndex);
    };

    const handlePrev = () => {
        const newIndex = Math.max(startIndex - 5, 0);
        setStartIndex(newIndex);
    };

    useEffect(() => {
        const getLeaderboard = async () => {
            try {
                const response = await axios.get(API_URL);
                setRankingData(response.data);
            } catch (error) {
                console.error('Errore durante il recupero dei dati della leaderboard:', error);
            }
        };

        // Avvia il polling
        const intervalId = setInterval(getLeaderboard, 5000);

        // Funzione di pulizia che viene chiamata quando il componente viene dismontato
        return () => clearInterval(intervalId);
    }, []);


    if(rankingData) {
        return (
            <div className={quizStyle.mainContainer}>
                <img src={logoPurple} alt={'logo'} className={quizStyle.logoPurple}/>
                <img src={logoAngelini} alt={'logo'} className={quizStyle.logoAngelini}/>
                <div className={rankingStyle.rankingHeader}>
                    <h2 style={{marginBottom: 0}}>TOTAL PLAYERS: {rankingData.totalPlayers}</h2>
                </div>
                <div className={rankingStyle.rankingHeader}>
                    <h2>RANKING:</h2>
                    <h2>AWARENESS:</h2>
                </div>
                {rankingData["leaderBoard"].slice(startIndex, startIndex + 5).map((element, index) => (
                    <div className={rankingStyle.rankingRow} key={element.country}>
                        <div className={rankingStyle.countryHeader}>
                            <h2 className={rankingStyle.rankingCountry}>{startIndex + index + 1}. {element.country}</h2>
                            {/* Render flag images */}
                            <AvatarGroup max={5}>
                                {flagMap[element.country].map((flag, flagIndex) => (
                                    <Avatar
                                        src={flag}
                                        alt={`${element.country} flag ${flagIndex}`}
                                        sx={{width: 24, height: 24}}
                                    />
                                ))}
                            </AvatarGroup>
                        </div>
                        <h2 className={rankingStyle.score}>{element.users === 0 ? 'N.A.' : (element.score.toFixed(2))} <span className={rankingStyle.percentage}>%</span></h2>
                    </div>
                ))}
                <div style={{display:'flex', flexDirection:'row', columnGap:'3vw'}}>
                    <CssButton sx={{width:'20vh'}} onClick={handlePrev} disabled={startIndex === 0}>Previous</CssButton>
                    <CssButton sx={{width:'20vh'}} onClick={handleNext} disabled={startIndex + 5 >= rankingData["leaderBoard"].length}>Next</CssButton>
                </div>

            </div>
        )
    }

    return (
        <div className={quizStyle.mainContainer}>
            <h1>Loading ranking data...</h1>
        </div>
    )
}

export default Ranking;