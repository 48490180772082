import axios from "axios";
import {useEffect, useState} from "react";

import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

import flagMap from "../../data/countryFlags";

import rankingStyle from "../Ranking.module.css";
import obsStyle from "./Obs.module.css"



const API_URL = 'https://purple-day-2024-api-33zerqsroq-ew.a.run.app/leaderboard';

function RankingObs() {
    const [rankingData, setRankingData] = useState(null);

    useEffect(() => {
        const getLeaderboard = async () => {
            try {
                const response = await axios.get(API_URL);
                setRankingData(response.data);
            } catch (error) {
                console.error('Errore durante il recupero dei dati della leaderboard:', error);
            }
        };

        // Avvia il polling
        const intervalId = setInterval(getLeaderboard, 5000);

        // Funzione di pulizia che viene chiamata quando il componente viene dismontato
        return () => clearInterval(intervalId);
    }, []);

    if(rankingData) {
        return (
            <div className={obsStyle.mainContainer}>
                <div className={rankingStyle.rankingHeader}>
                    <h2 style={{marginBottom:0}}>TOTAL PLAYERS: {rankingData.totalPlayers}</h2>
                </div>
                <div className={rankingStyle.rankingHeader}>
                    <h2>RANKING:</h2>
                    <h2>AWARENESS:</h2>
                </div>
                {rankingData.leaderBoard.map((element, index) => (
                    <div className={rankingStyle.rankingRow} key={element.country}>
                        <div className={rankingStyle.countryHeader}>
                            <h2>
                                <span className={'rank-index'}>{index + 1}.</span>
                                <span className={'country'}>{element.country}</span>
                            </h2>
                            {/* Render flag images */}
                            <span className={'flags'}>
                                <AvatarGroup max={5}>
                                {flagMap[element.country].map((flag) => (
                                    <Avatar
                                        className={'flag'}
                                        src={flag}
                                        alt={element.country}
                                        sx={{width: 24, height: 24}}
                                    />

                                ))}
                            </AvatarGroup>
                            </span>
                        </div>
                        <h2 className={'score'}>{element.users === 0 ? '00.00%' : (element.score.toFixed(2) + '%')} </h2>
                    </div>
                ))}

            </div>
        )
    }

    return (
        <div className={obsStyle.mainContainer}>

        </div>
    )
}

export default RankingObs;