import { useDocument } from 'react-firebase-hooks/firestore';
import {doc} from 'firebase/firestore';

import {db} from "../../firebase/clientApp";
import lastStyle from "../LastAnswer.module.css"
import obsStyle from "./Obs.module.css"

function LastAnswer() {

    const message = "THANKS FOR PLAYING \n"

    const [value, loading, error] = useDocument(
        doc(db, 'users', 'lastuser'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    if (loading) return (
        <div className={obsStyle.mainContainer}>
            <h1>Loading data...</h1>
        </div>
    );
    if (error) return <p>Error: {error.message}</p>;

    // Accessing the document's data
    const lastAnswer = value?.data();

    if(lastAnswer) {
        return (
            <div className={obsStyle.lastMainContainer}>
                <h2 style={{whiteSpace: "pre-wrap"}} className={'title'}>{message}
                    <span style={{color:"#6d78b7"}} className={'name'}>
                        {lastAnswer.displayName.toUpperCase()}
                    </span>
                </h2>
                <h2 style={{margin: 0}} className={'awareness-level'}>YOUR LEVEL OF AWARENESS IS:</h2>
                <h1 className={lastStyle.score} >{lastAnswer.score} </h1>
                <h2 style={{fontSize: '5vh', marginTop: '0vh',color:"#6d78b7"}} className={'out-of-60'}>OUT OF <span>60</span></h2>
            </div>
        )
    }

    return (
        <div className={obsStyle.mainContainer}>
            <h2>NO ONE PLAYED</h2>
        </div>
    )

}

export default LastAnswer;