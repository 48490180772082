import quizStyle from "./Quiz.module.css";
import podcastStyle from "./Podcast.module.css"
import logoPurple from "../assets/logo-purple-day.png";
import logoAngelini from "../assets/angelini-logo.png";
import {CssButton} from "../mui-styles/styles";
import {useNavigate} from "react-router-dom";

function Podcast() {
    const navigate = useNavigate();
    const playGame = () => {
        navigate('/remote-quiz');
    }
    return (
        <div className={quizStyle.mainContainer}>
            <img src={logoPurple} alt={'logo'} className={quizStyle.logoPurple}/>
            <img src={logoAngelini} alt={'logo'} className={quizStyle.logoAngelini}/>

            <div className={podcastStyle.videoHeader}>
                <h1 style={{margin: 0}}>Purple Day Openess</h1>
                <h3 style={{margin: 0}}>A special episode of Epilepsy Sparks Insights by Torie Robinson</h3>
                <p style={{margin: 0}}>Guesting: Cassidy Megan, founder of Purple Day</p>
            </div>
            <iframe src="https://www.youtube.com/embed/fbjbO_BzKh4?si=eIA8NpeWdJoZyVg-"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen
                    className={podcastStyle.video}
            >
            </iframe>
            <CssButton onClick={playGame} style={{marginTop:'5vh'}}>Play the Game</CssButton>
        </div>
    )
}

export default Podcast;