const QUESTIONS = [
    {
        id: 'q1',
        text: 'If you had an epileptic seizure in Italy, as in other Countries, how long do you need to be seizure free to be able to drive?',
        answers: [
            ['A', 'Minimum three months'],
            ['B', 'Minimum six months'],
            ['C', 'Minimum one year']
        ]
    },
    {
        id: 'q2',
        text: 'It’s never easy to make plans for future.\n People with epilepsy in Spain declare they can’t!\n How many of them feel like that?',
        answers: [
            ['A', '10%'],
            ['B', '40%'],
            ['C', '20%']
        ]
    },
    {
        id: 'q3',
        text: 'What are the main barriers to scientific research in Europe? \n (compared to the USA)',
        answers: [
            ['A', 'Low quality of researchers and institutions'],
            ['B', 'Insufficient funding'],
            ['C', 'Strict data privacy regulations']
        ]
    },
    {
        id: 'q4',
        text: 'How much is invested in epilepsy research in the United Kingdom compared to other neurological diseases?',
        answers: [
            ['A', 'One tenth'],
            ['B', 'Half as much'],
            ['C', 'More or less the same']
        ]
    },
    {
        id: 'q5',
        text: 'What is the wage gap between people with epilepsy and the general population in France?',
        answers: [
            ['A', 'There is no gap'],
            ['B', '5% less'],
            ['C', '12% less']
        ]
    },
    {
        id: 'q6',
        text: 'In Finland, Before year ____ people with epilepsy did not have the right to get married',
        answers: [
            ['A', '1874'],
            ['B', '1968'],
            ['C', '1929']
        ]
    }
]

export default QUESTIONS;