import austriaFlag from "../assets/countries/AUSTRIA.png";
import belgiumFlag from "../assets/countries/BELGIUM.png";
import bulgariaFlag from "../assets/countries/BULGARIA.png";
import czechFlag from "../assets/countries/CZECH.png";
import denmarkFlag from "../assets/countries/DENMARK.png";
import finlandFlag from "../assets/countries/FINLAND.png";
import franceFlag from "../assets/countries/FRANCE.png";
import germanyFlag from "../assets/countries/GERMANY.png";
import greeceFlag from "../assets/countries/GREECE.png";
import hungaryFlag from "../assets/countries/HUNGARY.png";
import icelandFlag from "../assets/countries/ICELAND.png";
import italyFlag from "../assets/countries/ITALY.png";
import netherlandsFlag from "../assets/countries/NETHERLANDS.png";
import norwayFlag from "../assets/countries/NORWAY.png";
import polandFlag from "../assets/countries/POLAND.png";
import portugalFlag from "../assets/countries/PORTUGAL.png";
import romaniaFlag from "../assets/countries/ROMANIA.png";
import russiaFlag from "../assets/countries/RUSSIA.png";
import slovakiaFlag from "../assets/countries/SLOVAKIA.png";
import spainFlag from "../assets/countries/SPAIN.png";
import swedenFlag from "../assets/countries/SWEDEN.png";
import switzerlandFlag from "../assets/countries/SWITZERLAND.png";
import turkeyFlag from "../assets/countries/TURKEY.png";
import ukFlag from "../assets/countries/UK.png";
import usaFlag from "../assets/countries/USA.png";

const flagMap = {
    "Fondazione Angelini": [italyFlag],
    "Angelini Holding": [italyFlag],
    "Angelini Real Estate": [italyFlag],
    "Angelini Ventures": [italyFlag],
    "Angelini Pharma Italy": [italyFlag],
    "Angelini Pharma Austria": [austriaFlag],
    "Angelini Pharma Bulgaria": [bulgariaFlag],
    "Angelini Pharma Czeck Rep": [czechFlag],
    "Angelini Pharma Slovakia" : [slovakiaFlag],
    "Angelini Pharma FRABENE": [franceFlag, belgiumFlag, netherlandsFlag],
    "Angelini Pharma Germany": [germanyFlag],
    "Angelini Pharma Greece": [greeceFlag],
    "Angelini Pharma Hungary": [hungaryFlag],
    "Angelini Pharma Nordics": [denmarkFlag, finlandFlag, icelandFlag, norwayFlag, swedenFlag],
    "Angelini Pharma Poland": [polandFlag],
    "Angelini Pharma Portugal": [portugalFlag],
    "Angelini Pharma Romania": [romaniaFlag],
    "Angelini Pharma Russia": [russiaFlag],
    "Angelini Pharma Spain": [spainFlag],
    "Angelini Pharma Switzerland": [switzerlandFlag],
    "Angelini Pharma Turkey": [turkeyFlag],
    "Angelini Pharma UK": [ukFlag],
    "Angelini Pharma US": [usaFlag]
};

export default flagMap;















